<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <img src="../assets/logo.png" alt="" class="">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-container">
          <p class="mt-5">Valitse päättymispäivä</p>

          <Datepicker
              :class="[errors.indexOf('continue_order') != -1 ? 'error' : '']"
              v-model="continueOrder"
              placeholder="dd.mm.yyyy"
              id="continue_order"
              format="dd.MM.yyyy"
              autoApply
              hideOffsetDates
              closeOnScroll
              :minDate="min_date"
              :disabledWeekDays="[6, 0]"
              :enableTimePicker="false"
              :clearable="false"
              :disabledDates="disabledDates"
              @update:modelValue="selectDate"
          />

          <p v-if="success" class="mt-3 text-success">Successfully Updated</p>

          <div class="stepNav d-flex justify-content-between mt-4">
            <button type="button" class="btn btn-primary" @click="saveOrderChanges">Tallenna muutokset</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import axios from "axios";
import moment from "moment";

export default {
  name: 'End',

  setup () {
    const store             = useStore();
    const route             = useRoute();
    const loading           = ref(true);
    const errors            = ref([]);
    const continueOrder     = ref('');
    const orderDetail       = ref('');
    const price             = ref(0);
    const success           = ref(false);

    const min_date = new Date();
    min_date.setDate(min_date.getDate() + 1);

    const getOrderDetails = async () => {
      let data = {
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/wp-json/api/boxes/" + route.params.id,
      };

      const res = await axios(data);

      if (res) {
        loading.value = false;
        orderDetail.value = res.data;
        continueOrder.value = res.data.details.pickup_date;
      }
    };
    const selectDate = () => {
      continueOrder.value = moment(continueOrder.value).format('YYYY-MM-DD');
    };
    const saveOrderChanges = async () => {
      const data = {
        id: orderDetail.value.id,
        pickup_date: continueOrder.value,
      };

      const res = await axios.post(process.env.VUE_APP_API_URL + '/wp-json/api/boxes/end-order',{data: data});

      if (res.data){
        success.value = true;
        setTimeout(() => success.value = false, 1000)
      }
    };

    const priceDetails    = computed(() => store.getters.getContinuePriceDetails);
    const disabledDates   = computed(() => {
      const dates = [];

      store.getters.getBlockedDates.map((item) => {
        item.block ? dates.push(new Date(item.date)) : '';
      });

      return dates
    });
    const blockedDates    = computed(() => store.getters.getBlockedDates);

    onMounted(() => {
      getOrderDetails();
      store.dispatch("getBlockedDates");
      store.dispatch("getContinuePriceDetails");
    });

    return {
      loading,
      errors,
      priceDetails,
      disabledDates,
      blockedDates,
      continueOrder,
      price,
      success,
      min_date,
      getOrderDetails,
      selectDate,
      saveOrderChanges,
    }
  }

}
</script>
